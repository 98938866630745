import axios from "../Utils/axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-switch";
import { useAlert } from 'react-alert'

function UpdatePrice(props) {
  const history = useHistory();
  const alert = useAlert();
  const [productId, setProductId] = useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  console.log(props.match.params.id);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProductPrice();
  }, [productId]);

  const getProductPrice = () => {
    setLoading(true);
    axios(`Products/get_seller_product_price?product_id=${productId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
          } else {
            setLoading(false);
            setProduct(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const productRemove = () => {
    setLoading(true);
    axios(`Products/delete_seller_product?product_id=${productId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          history.push({
            pathname: "/products",
            state: { data: true },
          });
        } else {
          alert.error(res.data.message)
        }
      })
      .catch((err) => console.log(err));
  }

  const productAdd = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("product_price", product.price);
    formdata.append("product_sale_price", product.sale_price);
    formdata.append("product_qty", product.stock_qty);
    formdata.append("warranty", product.warranty);
    formdata.append("product_id", productId);
    formdata.append("status", product.product_status);
    formdata.append("price_lock", product.price_lock);
    formdata.append("cost", product.cost);
    formdata.append("sku", product.sku);
    axios(`Products/update_price_changes_api`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          history.push({
            pathname: "/products",
            state: { data: true },
          });
        } else {
          setLoading(false);
          alert.error(res.data.message);
          console.log(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="card">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >

        <div class="card shadow mb-4">
          <div className="container">
            <div class="card-body">
              <h3 style={{ whiteSpace: 'pre-wrap' }}>{product.name}</h3>
              <h6>{product.brand}</h6>
              <br></br>
              <br></br>
              <div className="row">
                <div className="col-md-2">
                  <img alt="" src={product.image_url} className="img-fluid"></img>
                </div>
                <div className="col-md-10">
                  <form>

                    <div className="row">
                      <div className="col-md-4">
                        <div class="form-group">
                          <label htmlFor="productPrice">Price</label>
                          <input
                            type="text"
                            class="form-control"
                            value={product.price}
                            onChange={(e) =>
                              setProduct({ ...product, price: e.target.value })
                            }
                            id="productPrice"
                            placeholder="Price"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group">
                          <label htmlFor="productSpecialPrice">Special Price</label>
                          <input
                            type="text"
                            class="form-control"
                            value={product.sale_price}
                            onChange={(e) =>
                              setProduct({
                                ...product,
                                sale_price: e.target.value,
                              })
                            }
                            id="SpecialPrice"
                            placeholder="Special Price"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div class="form-group">
                          <label htmlFor="Cost">Cost</label>
                          <input
                            type="text"
                            class="form-control"
                            value={product.cost}
                            onChange={(e) =>
                              setProduct({
                                ...product,
                                cost: e.target.value,
                              })
                            }
                            id="Cost"
                            placeholder="Cost"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div class="form-group ">
                          <label htmlFor="productQty">Product Qty</label>
                          <input
                            type="text"
                            class="form-control"
                            value={[product.stock_qty]}
                            onChange={(e) =>
                              setProduct({
                                ...product,
                                stock_qty: e.target.value,
                              })
                            }
                            id="productQty"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div class="form-group ">
                          <label htmlFor="Warranty">Warranty</label>
                          <select
                            class="form-control"
                            onChange={(e) =>
                              setProduct({
                                ...product,
                                warranty: e.target.value,
                              })
                            }
                            id="Warranty"
                          >
                            <option
                              selected={product.warranty == 0 ? "selected" : ""}
                              value="0"
                            >
                              No Warranty
                            </option>
                            <option
                              selected={product.warranty == 1 ? "selected" : ""}
                              value="1"
                            >
                              1 Year
                            </option>
                            <option
                              selected={product.warranty == 2 ? "selected" : ""}
                              value="2"
                            >
                              2 Year
                            </option>
                            <option
                              selected={product.warranty == 3 ? "selected" : ""}
                              value="3"
                            >
                              3 Year
                            </option>
                            <option
                              selected={product.warranty == 4 ? "selected" : ""}
                              value="4"
                            >
                              4 Year
                            </option>
                            <option
                              selected={product.warranty == 5 ? "selected" : ""}
                              value="5"
                            >
                              1 Year International Warranty
                            </option>
                            <option
                              selected={product.warranty == 6 ? "selected" : ""}
                              value="6"
                            >
                              1 Year USA Warranty
                            </option>
                            <option
                              selected={product.warranty == 7 ? "selected" : ""}
                              value="7"
                            >
                              3 Months
                            </option>
                            <option
                              selected={product.warranty == 8 ? "selected" : ""}
                              value="8"
                            >
                              6 Months
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 d-flex pt-4 align-items-center">


                        <div class="form-group d-flex align-items-center pr-3">
                          <label className="p-0 mr-2">Status</label>
                          <Switch
                            onChange={(e) =>
                              setProduct({
                                ...product,
                                product_status: e ? 1 : 0,
                              })
                            }
                            checked={product.product_status == 1 ? 1 : 0}
                          />
                        </div>


                        <div class="form-group d-flex   align-items-center">
                          <label className="p-0 mr-2">Price Lock</label>

                          <Switch
                            onChange={(e) =>
                              setProduct({
                                ...product,
                                price_lock: e ? 1 : 0,
                              })
                            }
                            checked={product.price_lock == 1 ? 1 : 0}
                          />
                        </div>





                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <div class="form-group ">
                          <label htmlFor="productQty">Product SKU</label>
                          <input
                            type="text"
                            class="form-control"
                            value={[product.sku]}
                            onChange={(e) =>
                              setProduct({
                                ...product,
                                sku: e.target.value,
                              })
                            }
                            id="SKU"
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      onClick={productAdd}
                      class="btn btn-primary"
                    >
                      Submit
                    </button>


                    <button
                      type="button"
                      onClick={productRemove}
                      class="btn btn-danger ml-2"
                    >
                      Delete
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default UpdatePrice;
