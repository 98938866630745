import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../Utils/axios";
import { useHistory, Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  PresetDateRangePicker_panel: {
    padding: "0 22px 11px 22px",
  },

  PresetDateRangePicker_button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
  },

  PresetDateRangePicker_button__selected: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
    color: "white",
    background: "#138496",
  },
}));

function PriceByCost(props) {
  const history = useHistory();
  const alert = useAlert();
  const classes = useStyles();
  const today = moment();

  const [products, setProducts] = useState([]);
  //const [productId, setProductId] = useState(null);
  const [users, setUsers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectUser, setSelectUser] = useState(0);
  const [selectBrand, setSelectBrand] = useState(-1);
  const [selectRootCategory, setSelectRootCategory] = useState(0);
  const [rootCategory, setRootCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectSubCategory, setSelectSubCategory] = useState("");
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  //const [file, setFile] = useState();
  const [sort, setSort] = useState();
  const [sorting, setSorting] = useState("");
  const [disable, setDisable] = useState(false);
  const [startDate, setStartDate] = useState(moment().add(-2, "day"));
  const [endDate, setEndDate] = useState(today);
  const [focusedInput, setFocusedInput] = useState(null);
  //const [isOpen, setIsOpen] = useState(false);
  //   const lowprice = (
  //     <span>
  //       <i className="fa fa-check" style={{ color: "#0f0" }}></i> Lowest Price
  //     </span>
  //   );
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleChange = (index, dataType, value) => {
    setDisable(false);
    const newState = products.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setProducts(newState);
  };

  useEffect(() => {
    // products_list();
    getUsers();
    getBrands();
    getRootCategories();
    getMainCategories();
  }, []);
  useEffect(() => {
    products_list();
  }, [
    selectCategory,
    page,
    selectSubCategory,
    search,
    per_page,
    sort,
    sorting,
    endDate,
    startDate,
    selectUser,
    selectBrand,
    selectRootCategory,
  ]);
  //   const updatePrice = (id) => {
  //     history.push({
  //       pathname: `/update-price/${id}`,
  //     });
  //   };

  const yesterday = moment().add(-1, "day");
  const presets = [
    {
      text: "Today",
      start: today,
      end: today,
    },
    {
      text: "Yesterday",
      start: yesterday,
      end: yesterday,
    },
    {
      text: "This Week",
      start: moment().add(-1, "week"),
      end: today,
    },
    {
      text: "This Month",
      start: moment().add(-1, "month"),
      end: today,
    },
    {
      text: "This Year",
      start: moment().add(-1, "year"),
      end: today,
    },
  ];

  const onDatesChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
  };

  function isSameDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    // Compare least significant, most likely to change units first
    // Moment's isSame clones moment inputs and is a tad slow
    return (
      a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
    );
  }

  function renderDatePresets() {
    return (
      <div className={classes.PresetDateRangePicker_panel}>
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              className={
                isSelected
                  ? classes.PresetDateRangePicker_button__selected
                  : classes.PresetDateRangePicker_button
              }
              type="button"
              onClick={() => onDatesChange(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  const changeSorting = (sortdata) => {
    var str1 = [];
    var str2 = [];
    str1 = sortdata.split("-");
    str2 = sorting.split("-");
    if (str1[0] == str2[0]) {
      str2[1] == "asc" ? (str1[1] = "desc") : (str1[1] = "asc");
      setSorting(str1[0] + "-" + str1[1]);
    } else {
      setSorting(str1[0] + "-" + "asc");
    }
  };

  const updateData = () => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("products", JSON.stringify(products));
     axios(`Products/update_product_data_changes_api`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          products_list();
          setLoading(false);
        } else {
          setLoading(false);
          alert.error(res.data.message);
          //console.log(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  //   const uploadCSV = () => {
  //     setLoading(true);
  //     let formdata = new FormData();
  //     formdata.append("file", file);
  //     axios(`Products/importUploadData`, {
  //       method: "POST",
  //       data: formdata,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         "x-api-key": JSON.parse(localStorage.getItem("data")),
  //       },
  //     })
  //       .then((res) => {
  //         console.log(res);
  //         if (res.data.status) {
  //           setLoading(false);
  //           alert.success(res.data.message);
  //           products_list();
  //         } else {
  //           setLoading(false);
  //           alert.error(res.data.message);
  //           //console.log(res.data);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   };

  const getMainCategories = () => {
    axios(`Products/get_main_category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCategories(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getUsers = () => {
    axios(`Users/get_users_by_seller`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setUsers(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getBrands = () => {
    axios(`Products/get_brands`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setBrands(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getRootCategories = () => {
    axios(`Products/get_root_category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setRootCategory(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getSubCategories = async (value) => {
    setSelectCategory(value);
    setSelectSubCategory(0);

    axios(`Products/get_sub_category?main_category=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (res.data === 0) {
          } else {
            setSubCategories(res.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const products_list = async () => {
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    let formdata = new FormData();
    formdata.append("search", search);
    formdata.append("sort", sorting);
    formdata.append("start_date", start);
    formdata.append("end_date", end);
    formdata.append("main_category", selectCategory);
    formdata.append("sub_category", selectSubCategory);
    formdata.append("root_category", selectRootCategory);
    formdata.append("user", selectUser);
    formdata.append("brand_id", selectBrand);
    formdata.append("page", page);
    formdata.append("per_page", per_page);
    formdata.append("status", "all");
    setLoading(true);
    axios(`Products/seller_product_cost_list`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (res.data.data.length === 0) {
            setProducts([]);
            //props.setAllcount(res.data.total_count);
            setLoading(false);
          } else {
            setProducts(res.data.data);
            //props.setAllcount(res.data.total_count);
            setTotal(res.data.total);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => setSelectRootCategory(e.target.value)}
                >
                  <option value="0">Select Root Category</option>
                  {rootCategory.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => getSubCategories(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {categories.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => setSelectSubCategory(e.target.value)}
                >
                  <option value="0">Select Sub Category</option>
                  {subCategories.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  onChange={(e) => setSelectBrand(e.target.value)}
                >
                  <option value="-1">Select Brands</option>
                  {brands.map((value) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                  <option value="0">Others</option>
                </select>
              </div>
            </div>

            <div className="row" style={{ marginTop: 20 }}>
              <div className="col-md-6">
                <DateRangePicker
                  presets={presets}
                  renderCalendarInfo={renderDatePresets}
                  showClearDates={true}
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: 20 }}></div>

            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  <table className="table table-bordered sortable  ">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          Product Name{" "}
                          <i
                            className="fas fa-fw fa-sort"
                            onClick={() => changeSorting("product")}
                          ></i>
                        </th>
                        <th>
                          Product Image{" "}
                          <i
                            className="fas fa-fw fa-sort"
                            onClick={() => changeSorting("product")}
                          ></i>
                        </th>
                        <th>
                          Cost{" "}
                          <i
                            className="fas fa-fw fa-sort"
                            onClick={() => changeSorting("cost")}
                          ></i>
                        </th>
                        <th>
                          Actual Price{" "}
                          <i
                            className="fas fa-fw fa-sort"
                            onClick={() => changeSorting("price")}
                          ></i>
                        </th>
                        <th>
                          Offer Price{" "}
                          <i
                            className="fas fa-fw fa-sort"
                            onClick={() => changeSorting("sale_price")}
                          ></i>
                        </th>

                        <th>
                          Date Updated{" "}
                          <i
                            className="fas fa-fw fa-sort"
                            onClick={() => changeSorting("date")}
                          ></i>
                        </th>

                        <th>
                          <input
                            type="button"
                            className="btn btn-success btn-sm"
                            disabled={disable}
                            onClick={() => {
                              if (window.confirm("All selected product prices should be updated automatically. Are You Sure ?!")) {
                                updateData();
                              }
                            }}
                            value="Update"
                          ></input>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {products.map((value, index) => {
                        return (
                          <tr
                            key={index}
                            style={{ background: value.cost_def }}
                          >
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleChange(index, "checked", !value.checked)
                                }
                                //onChange={() => console.log()}
                                checked={value.checked}
                                onClick={(e) => {}}
                                value={value.product_id}
                              ></input>
                            </td>
                            <td
                              style={{
                                maxWidth: "400px",
                              }}
                            >
                              <span>{value.product_name}</span>
                            </td>
                            <td>
                              <img alt="" src={value.product_img}></img>
                            </td>
                            <td>
                              <span>{value.old_cost}</span>
                              <br></br>
                              <input
                                type="text"
                                style={{
                                  borderStyle: "solid",
                                  borderColor: "red",
                                }}
                                className="form-control"
                                disabled
                                onChange={(e) =>
                                  handleChange(index, "cost", e.target.value)
                                }
                                value={value.cost}
                              ></input>
                            </td>
                            <td>
                              <span>{value.old_price}</span>
                              <br></br>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) =>
                                  handleChange(index, "price", e.target.value)
                                }
                                value={value.price}
                              ></input>
                            </td>
                            <td>
                              <span>{value.old_sale_price}</span>
                              <br></br>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "sale_price",
                                    e.target.value
                                  )
                                }
                                value={value.sale_price}
                              ></input>
                            </td>

                            <td
                              style={{
                                maxWidth: "100px",
                              }}
                            >
                              {value.created_date}
                            </td>
                            <td>
                              {value.cost_change == 0 ? (
                                <span style={{ color: "red" }}>
                                  {" "}
                                  <i className="fa   fa-arrow-down fa-3x" title="cost decreased"></i>
                                </span>
                              ) : value.cost_change == 1 ? (
                                <span>
                                  <i className="fa   fa-arrow-right fa-3x" title="cost no change"></i>
                                </span>
                              ) : (
                                <span style={{ color: "green" }} title="cost increased">
                                  <i className="fa   fa-arrow-up fa-3x"></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceByCost;
