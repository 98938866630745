import React, { useContext, useEffect } from "react";
import Header from "./Components/Templates/Header";
import Products from "./Components/Products/Products";
import Orders from "./Components/Orders/Orders";
import { Route, Switch, Redirect } from "react-router-dom";
import SideBar from "./Components/Templates/SideBar";
import Footer from "./Components/Templates/Footer";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import Profile from "./Components/Login/Profile";
import AddProduct from "./Components/Products/AddProduct";
import UpdatePrice from "./Components/Products/UpdatePrice";
import OrderDetails from "./Components/Orders/OrderDetails";
import Users from "./Components/Users/Users";
import UserAdd from "./Components/Users/AddUser";
import UserRoles from "./Components/Users/UserRoles";
import UpdateUser from "./Components/Users/UpdateUser";
import IsAllowed from "./Components/Utils/IsAllowed";
import { AuthContext } from "./Context/AuthContext";
import axios from "./Components/Utils/axios";
import ForgotPassword from "./Components/Login/ForgotPassword";
import ResetPassword from "./Components/Login/ResetPassword";
import OfferPercentage from "./Components/Settings/OfferPercentageList";
import CostPercentage from "./Components/Settings/CostPercentageList";
import PriceByCost from "./Components/Products/PriceByCost";
import TransactionDetails from "./Components/Payments/TransactionDetails";
import PayoutDetails from "./Components/Payments/PayoutDetails";
import Transactions from "./Components/Payments/Transactions";
function App() {
  const { role, setRole, setPermissions, setFeatures, setCategory } =
    useContext(AuthContext);

  useEffect(() => {
    if (!role) {
      axios(`index/get_seller_user_role_permission`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (!res.data.status) {
          } else {
            setRole(res.data.data.role);
            setPermissions(res.data.data.permissions);
            setFeatures(res.data.data.features);
          }
        })
        .catch((err) => console.log(err));
    }
  });
  return (
    <div id="wrapper">
      <Switch>
        <LoginSideBar />
      </Switch>

      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Switch>
            <LoginHeader />
          </Switch>
          <Switch>
            {IsAllowed(["order_view"]) && (
              <ProtectedRoute path="/orders" exact component={Orders} />
            )}

            {IsAllowed(["product_view"]) && (
              <ProtectedRoute path="/products" exact component={Products} />
            )}

            {IsAllowed(["product_edit"],["product_edit_cost"]) && (
              <ProtectedRoute
                path="/product-edit-cost"
                exact
                component={PriceByCost}
              />
            )}

            <ProtectedRoute path="/profile" exact component={Profile} />

            <ProtectedRoute path="/add-products" exact component={AddProduct} />
            <ProtectedRoute
              path="/update-price/:id"
              exact
              component={UpdatePrice}
            />
            <ProtectedRoute
              path="/order-details/:id"
              exact
              component={OrderDetails}
            />
            <ProtectedRoute
              path="/transactions"
              exact
              component={Transactions}
            />

            <ProtectedRoute
              path="/transaction-details/:id"
              exact
              component={TransactionDetails}
            />
            <ProtectedRoute
              path="/payout-details/:id"
              exact
              component={PayoutDetails}
            />
            {IsAllowed(["offer_percentage_update"],["price_setting"]) && (
              <ProtectedRoute
                path="/offer-percentage"
                exact
                component={OfferPercentage}
              />
            )}
            {IsAllowed(["cost_percentage_view"],["price_setting"]) && (
              <ProtectedRoute
                path="/cost-percentage"
                exact
                component={CostPercentage}
              />
            )}
            {IsAllowed(["user_view"]) && (
              <ProtectedRoute path="/users" exact component={Users} />
            )}
            {IsAllowed(["user_add", "user_view"]) && (
              <ProtectedRoute path="/user-add" exact component={UserAdd} />
            )}
            {IsAllowed(["user_view"]) && (
              <ProtectedRoute path="/user-roles" exact component={UserRoles} />
            )}
            {IsAllowed(["user_edit"]) && (
              <ProtectedRoute path="/user-edit" exact component={UpdateUser} />
            )}
            <Route path="/login" exact component={Login} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route
              path="/reset-password/:key1/:key2"
              exact
              component={ResetPassword}
            />
            <ProtectedRoute path="/" exact component={Dashboard} />
          </Switch>
        </div>
      </div>
      <Switch>
        <LoginFooter />
      </Switch>
    </div>
  );
}

const ProtectedRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              refresh: true,
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};
const LoginHeader = () => {
  return localStorage.getItem("data") ? <Header /> : "";
};
const LoginSideBar = () => {
  return localStorage.getItem("data") ? <SideBar /> : "";
};
const LoginFooter = () => {
  return localStorage.getItem("data") ? <Footer /> : "";
};

export default App;
