import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../Utils/axios";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Switch from "react-switch";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
function Users() {

  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const alert = useAlert();
  useEffect(() => {
    users_list();
  }, [page,per_page]);
  const updateUser = (id) => {
    history.push({
      pathname: "/user-edit",
      state: { id: id },
    });
  };

  const updateStatus = (e, user_id) => {
    setLoading(true);
    let status;
    e ? (status = 1) : (status = 0);
    axios(
      `Users/updateSellerUserStatus?user_id=${user_id}&status=${status}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.status === true) {
         users_list();
         setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };


  const users_list = () => {
    setLoading(true);
    axios(
      `Users/get_seller_users?page=${page}&per_page=${per_page}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setUsers([]);
            setLoading(false);
          } else {
            setUsers(res.data.data);
            setTotal(res.data.total);
            setPer_page(10);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

    return (
        <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Users</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="Loading ..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>role</th>
                        <th>Created Date</th>
                        <th>Action</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {users.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.name}</td>
                            <td>{value.email}</td>
                            <td>{value.role}</td>
                            <td>{value.created_at}</td>
                            <td><input type="button" className="btn btn-primary" disabled={value.role_id == 1 ? true : false} onClick={()=>{updateUser(value.id)}} value="Edit"></input></td>
                            <td>
                            <Switch
                                 disabled={value.role_id == 1 ? true : false}
                                onChange={(e) =>
                                  updateStatus(e, value.id)
                                }
                                checked={value.status === "0" ? 0 : 1}
                              /> 
                            </td>
                        
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default Users



