import axios from "../Utils/axios";
import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-switch";
import { useAlert } from 'react-alert'
import { BASE_URL } from "../../constants/constants";
function AddProduct() {
  const history = useHistory();
  const [inputValue, setValue] = useState("");
  const [productId, setProductId] = useState(null);
  const [status, setStatus] = useState(null);
  const [product, setProduct] = useState({});
  const [form, setForm] = useState("none");
  const [loading, setLoading] = useState(false);
  const alert =useAlert();

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (value) => {
    setProductId(value);
  };
  
  const loadOptions = (inputValue) => {
    const headers = { "x-api-key": JSON.parse(localStorage.getItem("data"))}
    return fetch(`${BASE_URL}Products/getProductByKey?q=${inputValue}`,{ headers }).then(
      (res) => res.json()
    );
  };

  // const selectProduct = () => {

  // }

  const selectProduct = () => {
    if (productId && productId.id) {
      setLoading(true);
      axios(`Products/seller_product_check?product_id=${productId.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.data) {
            console.log(productId.id);
            setLoading(false);
            history.push({
              pathname: `/update-price/${productId.id}`,
            });
          } else {
            axios(
              `Products/get_product_details_api?product_id=${productId.id}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  "x-api-key": JSON.parse(localStorage.getItem("data")),
                },
              }
            ).then((res) => {
              setProduct(res.data);
              setForm("block");
              setLoading(false);
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert.show("select any product");
    }
  };

  const productAdd = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("product_price", product.price);
    formdata.append("product_sale_price", product.special_price);
    formdata.append("product_qty", product.inventory);
    formdata.append("product_id", productId.id);
    formdata.append("warranty", product.warranty);
    formdata.append("status", status);
    formdata.append("cost", product.cost);
    formdata.append("sku", product.sku);
    axios(`Products/update_price_changes_api`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          history.push({
            pathname: "/products",
            state: { data: true },
          });
        } else {
          setLoading(false);
          alert.show(res.data.message);
          console.log(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.9)",
        }),
        spinner: (base) => ({
          ...base,
          width: "75px",
          "& svg circle": {
            stroke: "rgba(0, 0, 0, 0.8)",
          },
        }),
      }}
      spinner
      text="Loading ..."
    >
      <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8">
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={productId}
              getOptionLabel={(e) => e.text}
              getOptionValue={(e) => e.id}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
              onChange={handleChange}
            />
          </div>
          <div class="col-md-2 offset-md-2">
            <button
              type="button"
              onClick={selectProduct}
              class="btn btn-primary"
            >
              Add product
            </button>
          </div>
        </div>

        <div class="card" style={{ display: form }}>
          <h3>{product.name}</h3>
          <h3>{product.brand}</h3>
          <img alt="" src={product.image_url} width="300" height="300"></img>
          <form>
            <div class="form-group">
              <label htmlFor="productPrice">Price</label>
              <input
                type="text"
                class="form-control"
                value={product.price}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    price: e.target.value,
                  })
                }
                id="productPrice"
                placeholder="Price"
              />
            </div>
            <div class="form-group">
              <label htmlFor="productSpecialPrice">Special Price</label>
              <input
                type="text"
                class="form-control"
                value={product.special_price}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    special_price: e.target.value,
                  })
                }
                id="productSpecialPrice"
                placeholder="Secial Price"
              />
            </div>
            <div class="form-group">
              <label htmlFor="productCost">Cost</label>
              <input
                type="text"
                class="form-control"
                value={product.cost}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    cost: e.target.value,
                  })
                }
                id="productCost"
                placeholder="Cost"
              />
            </div>
            <div class="form-group ">
              <label htmlFor="productQty">Product Qty</label>
              <input
                type="text"
                class="form-control"
                value={product.inventory}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    inventory: e.target.value,
                  })
                }
                id="productQty"
              />
            </div>
            <div class="form-group ">
              <label htmlFor="productQty">Product SKU</label>
              <input
                type="text"
                class="form-control"
                value={product.sku}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    sku: e.target.value,
                  })
                }
                id="productQty"
              />
            </div>
            <div class="form-group ">
              <label htmlFor="Warranty">Warranty</label>
              <select
                class="form-control"
                onChange={(e) =>
                  setProduct({
                    ...product,
                    warranty: e.target.value,
                  })
                }
                id="Warranty"
              >
                <option
                  selected={product.warranty == 0 ? "selected" : ""}
                  value="0"
                >
                  No Warranty
                </option>
                <option
                  selected={product.warranty == 1 ? "selected" : ""}
                  value="1"
                >
                  1 Year
                </option>
                <option
                  selected={product.warranty == 2 ? "selected" : ""}
                  value="2"
                >
                  2 Year
                </option>
                <option
                  selected={product.warranty == 3 ? "selected" : ""}
                  value="3"
                >
                  3 Year
                </option>
                <option
                  selected={product.warranty == 4 ? "selected" : ""}
                  value="4"
                >
                  4 Year
                </option>
                <option
                  selected={product.warranty == 5 ? "selected" : ""}
                  value="5"
                >
                  1 Year International Warranty
                </option>
                <option
                  selected={product.warranty == 6 ? "selected" : ""}
                  value="6"
                >
                  1 Year USA Warranty
                </option>
                <option
                  selected={product.warranty == 7 ? "selected" : ""}
                  value="7"
                >
                  3 Months
                </option>
                <option
                  selected={product.warranty == 8 ? "selected" : ""}
                  value="8"
                >
                  6 Months
                </option>
              </select>
            </div>

            <div class="form-group ">
              <Switch
                onChange={(e) => setStatus(e ? 1 : 0)}
                checked={status == 1 ? 1 : 0}
              />
            </div>

            <button type="button" onClick={productAdd} class="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div></div>
    </LoadingOverlay>
  );
}
export default AddProduct;
