import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../Utils/axios";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

function CostPercentageList() {
  const history = useHistory();
  const [costPercentage, setCostPercentage] = useState([]);
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState();
  const [category, setCategory] = useState();
  const [percentage, setPercentage] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const alert = useAlert();
  useEffect(() => {
    getRootCategories();
  }, []);
  useEffect(() => {
    cost_percentage_list();
  }, [page]);

  const getRootCategories = () => {
    axios(`Settings/get_all_root_category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          setCategories(res.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const syncCost = (id) =>{
    setLoading(true);
    axios(
      `Products/update_price_by_category_cost?category=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          alert.show(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  }
  const cost_percentage_list = () => {
    setLoading(true);
    axios(
      `Settings/get_cost_percentage?page=${page}&per_page=${per_page}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setCostPercentage([]);
            setLoading(false);
          } else {
            setCostPercentage(res.data.data);
            setTotal(res.data.total);
            setPer_page(10);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const addCostPercentage = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("category", category);
    formdata.append("percentage", percentage);
    axios(`Settings/add_cost_percentage`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          if (res.data.data) {
            cost_percentage_list();
          } else {
            if (window.confirm(res.data.message)) {
              updateCostPercentage(event);
            }
          }
        } else {
          setLoading(false);
          alert.show(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const updateCostPercentage = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("category", category);
    formdata.append("percentage", percentage);
    axios(`Settings/update_cost_percentage`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          cost_percentage_list();
        } else {
          setLoading(false);
          alert.show(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const editData = (value) =>{
     setPercentage(value.cost_percentage)
     setCategory(value.category_id)
  }
  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Cost percentage</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row">
              <div className="col-md-5">
                <select
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                  className="form-control"
                >
                  <option value="">Select Category</option>
                  {categories.map((value) => {
                    return (
                      <option checked={category==value.id ? 'checked': ''} value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Percentage"
                  onChange={(e) => setPercentage(e.target.value)}
                  value={percentage}
                  className="form-control"
                ></input>
              </div>
              <div className="col-md-2">
                <button
                  className="btn btn-primary form-control"
                  onClick={addCostPercentage}
                >
                  Add
                </button>
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="Loading ..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Category</th>
                        <th>Percentage</th>
                        <th>Created Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {costPercentage.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value.category}</td>
                            <td>{value.cost_percentage}</td>
                            <td>{value.created_at}</td>
                            <td>
                              {/* <button
                                onClick={()=>syncCost(value.category_id)}
                                type="button"
                                title="Sync"
                                className="btn btn-success"
                              >
                                <i className="fa fa-sync"></i>
                              </button> */}
                              <button
                                type="button"
                                title="Edit"
                                className="btn btn-primary"
                                onClick={()=>{editData(value)}}
                              >
                                <i className="fa fa-pen"></i>
                              </button>
                              
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <select onChange={(e) => setPer_page(e.target.value)}>
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={total / per_page}
                    onPageChange={(page) => setPage(page.selected + 1)}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CostPercentageList;
