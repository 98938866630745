import React, { useState ,useContext, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Orders.css';
import PendingOrders from './OrderTabs/PendingOrders';
import ConfirmedOrders from './OrderTabs/ConfirmedOrders';
import PackedOrders from './OrderTabs/PackedOrders';
import CancelledOrders from './OrderTabs/CancelledOrders';
import ShippedOrders from './OrderTabs/ShippedOrders'
import DeliveredOrders from './OrderTabs/DeliveredOrders'
import { AuthContext } from "../../Context/AuthContext";
function Products() {
  const [allcount, setAllcount] = useState(0);
  const [shippedcount, setShippedcount] = useState(0);
  const [deliveredcount, setDeliveredcount] = useState(0);
  const [pendingcount,setPendingcount] = useState(0);
  const [confirmedcount,setConfirmedcount] = useState(0);
  const [packecount,setPackedcount] = useState(0);
  const [cancelledcount,setCancelledcount] = useState(0);
  
  return (
    <Tabs>
      <TabList>
        <Tab>Pending <span class="badge badge-warning badge-counter">{pendingcount}</span></Tab>
        {/* <Tab>Confirmed <span class="badge badge-success badge-counter">{confirmedcount}</span></Tab> */}
        <Tab>Ready For Pickup <span class="badge badge-info badge-counter">{packecount}</span></Tab>
        <Tab>Shipped <span class="badge badge-primary badge-counter">{shippedcount}</span></Tab>
        <Tab>Canceled <span class="badge badge-danger badge-counter">{cancelledcount}</span></Tab>
       
        <Tab>Delivered <span class="badge badge-secondary badge-counter">{deliveredcount}</span></Tab>
      </TabList>

      <TabPanel>
        <PendingOrders setPendingcount={setPendingcount}></PendingOrders>
      </TabPanel>
      {/* <TabPanel>
        <ConfirmedOrders setConfirmedcount={setConfirmedcount}></ConfirmedOrders>
      </TabPanel> */}
      <TabPanel>
        <PackedOrders setPackedcount={setPackedcount}></PackedOrders>
      </TabPanel>
      <TabPanel>
        <ShippedOrders setShippedcount={setShippedcount}></ShippedOrders>
      </TabPanel>
      <TabPanel>
        <CancelledOrders setCancelledcount={setCancelledcount}></CancelledOrders>
      </TabPanel>
     
      <TabPanel>
        <DeliveredOrders setDeliveredcount={setDeliveredcount}></DeliveredOrders>
      </TabPanel>
    </Tabs>
  );
}

export default Products;
