import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../Utils/axios";
import { useHistory, Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Switch from "react-switch";
import Pagination from "@mui/material/Pagination";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import fileDownload from "js-file-download";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  PresetDateRangePicker_panel: {
    padding: "0 22px 11px 22px",
  },

  PresetDateRangePicker_button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
  },

  PresetDateRangePicker_button__selected: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
    color: "white",
    background: "#138496",
  },
}));

function Payout(props) {
  const history = useHistory();
  const today = moment();
  const classes = useStyles();
  const [transactions, setTransactions] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [focusedInput, setFocusedInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const alert = useAlert();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };




  useEffect(() => {
    getTransactions();
  }, [page, per_page, search, startDate, endDate]);


  //const today = moment();
  const yesterday = moment().add(-1, "day");
  const presets = [
    {
      text: "Today",
      start: today,
      end: today,
    },
    {
      text: "Yesterday",
      start: yesterday,
      end: yesterday,
    },
    {
      text: "This Week",
      start: moment().add(-1, "week"),
      end: today,
    },
    {
      text: "This Month",
      start: moment().add(-1, "month"),
      end: today,
    },
    {
      text: "This Year",
      start: moment().add(-1, "year"),
      end: today,
    },
  ];

  const onDatesChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
  };

  function isSameDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    // Compare least significant, most likely to change units first
    // Moment's isSame clones moment inputs and is a tad slow
    return (
      a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
    );
  }

  function renderDatePresets() {
    return (
      <div className={classes.PresetDateRangePicker_panel}>
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              className={
                isSelected
                  ? classes.PresetDateRangePicker_button__selected
                  : classes.PresetDateRangePicker_button
              }
              type="button"
              onClick={() => onDatesChange(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  const export_payout_list = async (payout_id) => {
    setLoading(true);

    axios(
      `Transactions/export_seller_payout_list?txn_id=${payout_id}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, "payout.csv");
      })
      .catch((err) => console.log(err));
  };

  const getTransactions = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Transactions/get_seller_payout?page=${page}&per_page=${per_page}&start_date=${start}&end_date=${end}&search=${search}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setTransactions([]);
            props.setPayout(res.data.total_count);
            setLoading(false);
            setTotal(res.data.total);
          } else {
            setTransactions(res.data.data);
            props.setPayout(res.data.total_count);
            setTotal(res.data.total);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Payouts</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row order-row">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control  form-control-lg "
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>

              <div className="col-md-5">
                <DateRangePicker
                  presets={presets}
                  renderCalendarInfo={renderDatePresets}
                  showClearDates={true}
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>

            </div>

            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>Payout Id</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Action</th>


                      </tr>
                    </thead>

                    <tbody>
                      {transactions.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td> <Link
                              //style={{ cursor: "pointer", color: "#0645AD" }}
                              to={{
                                pathname: `/payout-details/${value.id}`,
                              }}>{value.id}</Link></td>
                            <td>{value.currency + " " + value.amount}</td>
                            <td>{value.created_at}</td>
                            <td className="space-evenly">

                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={() => export_payout_list(value.id)}
                                title="Export"
                              > <i className="fa fa-print  fa-sm"></i></button>
                              <Link
                                //style={{ cursor: "pointer", color: "#0645AD" }}
                                to={{
                                  pathname: `/payout-details/${value.id}`,
                                }}
                              ><button
                                type="button"
                                className="btn btn-info btn-sm"
                                title="View Details"
                              ><i className="fa fa-eye  fa-sm"></i></button></Link></td>



                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payout;
