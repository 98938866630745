import React, { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "../Utils/axios";
import { AuthContext } from "../../Context/AuthContext"
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from 'react-alert'
import './Login.css';
function Login() {
  const history = useHistory();
  const alert = useAlert();
  const { setRole, setPermissions, setFeatures } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append('username', username);
    formdata.append('password', password);
    axios("index/seller_login", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.status === true) {
          localStorage.setItem(
            "data",
            JSON.stringify(response.data.data.api_key)
          );
          setPermissions(response.data.data.permissions);
          setRole(response.data.data.role);
          setFeatures(response.data.data.features)


          history.push({
            pathname: "/",
            state: { data: true },
          });

        } else {
          setLoading(false);
          setError(response.data.message)
          setTimeout(() => {
            setError('')
          }, 5000);
        }
      })
      .catch((err) => console.log(err));
  };
  const [password, setPassword] = useState("");
  const [username, setUserName] = useState("");

  return (

    <>
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
        rel="stylesheet"
      />
      <link href="assets/css/bootstrap.min.css" rel="stylesheet" />
      <link href="assets/css/bootstrap-icons.css" rel="stylesheet" />
      <link href="assets/css/boxicons.min.css" rel="stylesheet" />
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        rel="stylesheet"
      />
      <link href="assets/css/style.css" rel="stylesheet" />
      {/* ======= Header ======= */}
      <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent"
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <a href="index.html">
              <img src="assets/img/logo.svg" />
            </a>
          </div>
          <nav id="navbar" className="navbar">
            <ul>

              <li>
                <a className="nav-link scrollto" href="#about">
                  Benefits
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#faq">
                  FAQ
                </a>
              </li>
              {/* <li>
                <a className="nav-link scrollto" href=" ">
                  Pricing
                </a>
              </li> */}
              <li>
                <a className="nav-link scrollto" href="https://www.shopkees.com/uae-en/contact.html" target="__blank">
                  Contact
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" />
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
      {/* ======= Hero Section ======= */}
      <section id="hero">
        <div className="container pb-5">
          <div className="row justify-content-between">
            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div>
                <h1>
                  THE SELLING STORE YOU ALWAYS WISHED FOR IS HERE

                </h1>
                <h2>
                  Shopkees seller platform is finally here . Sell your products and reach those valuable buyers
                </h2>
                <div className="text-center text-lg-start">
                  <a href="https://www.shopkees.com/seller-registration" target="__blank" className="btn-get-started scrollto">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-1 order-lg-2 hero-img">
              {/* <img src="assets/img/banner.png" class="img-fluid" alt=""> */}
              <div className="login-seller">
                <div className="p-5">
                  <div className="text-center">
                    <h2 className="h4 text-gray-900 mb-4 log">Login <p style={{ fontSize: 15, lineHeight: 1, fontWeight: 400 }}>
                      Already a seller please login
                    </p></h2>
                  </div>

                  <form className="user" action="/login" method="POST">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-user"
                        name="username"
                        id="exampleInputEmail"
                        aria-describedby="emailHelp"
                        placeholder="Enter Email Address..."
                        onChange={(e) => setUserName(e.target.value)}
                        value={username}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-user"
                        name="password"
                        id="exampleInputPassword"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </div>
                    <div>
                      {error ? <span style={{ color: 'red' }}>{error}</span> : null}
                    </div>
                    <div className="shw-pasrd d-flex p-1 justify-content-between">
                      <div>
                        <input
                          type="checkbox"
                          onclick="myFunction()"
                          className="mr-2"
                          id="showpassword"
                        />
                        <label htmlFor="showpassword" className="small">
                          {" "}
                          Show Password
                        </label>{" "}
                      </div>{" "}
                      <a className="small f-p" href="/forgot-password">
                        Forgot Password?
                      </a>
                    </div>
                    <div className="form-group ">
                      <input
                        type="button"
                        className="btn btn-primary w-100 btn-user btn-block"
                        defaultValue="Login"
                        onClick={handleSubmit}
                      />
                    </div>
                    <hr />
                  </form>
                  <div className="text-center small">
                    Do not have an account?
                    <a className="f-p " target="__blank" href="https://www.shopkees.com/seller-registration">
                      {" "}
                      Signup
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero */}
      <main id="main">
        {/* ======= About Section ======= */}
        <section id="about" className="about">
          <div className="container-fluid">
            <iframe src="https://www.youtube.com/embed/M7vzDuT8qPs" style={{"margin": "0 auto","display": "flex","padding": "34px"}} width="600" height="450">
            </iframe>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 icon-boxes text-center">
                <h3>Sell Where You Are Seen</h3>
                <p>
                  Shopkees draws a large customer base across UAE and if you are a registered company or an individual seller with a business that’s registered then this is the place for you. Set up your digital stall here and see how fast your products are sold. Explore the power of e-commerce with us.
                </p>
              </div>
              <div className="col-xl-10 col-lg-10 icon-boxes  ">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="icon-box bg">
                      <div className="img-icon">
                        <img src="assets/img/to-do-list.png" />
                      </div>
                      <h4 className="title color1">
                        <a href="" >Let’s Get Started</a>
                      </h4>
                      <p className="description">
                        Register to create a personalised Shopkees Seller account. And once approved, your Shopkees stall is ready to sell
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3  mt-4">
                    <div className="icon-box bg">
                      <div className="img-icon">
                        <img src="assets/img/order-delivery.png" />
                      </div>
                      <h4 className="title color2">
                        <a href="">Receive Orders across GCC</a>
                      </h4>
                      <p className="description">
                        Once the Dashboard is set up, you will see requests coming to you across GCC. Manage them with our smart dashboard

                      </p>
                    </div>
                  </div>
                  <div className="col-md-3  mt-4">
                    <div className="icon-box bg">
                      <div className="img-icon">
                        <img src="assets/img/delivery-truck.png" />
                      </div>
                      <h4 className="title color3">
                        <a href="">Ship Your Orders</a>
                      </h4>
                      <p className="description">
                        Nothing pleases a customer like fast delivery, so once you accept the orders, make it ready for collection and we take it from there for you.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3  mt-4">
                    <div className="icon-box bg">
                      <div className="img-icon">
                        <img src="assets/img/trade.png" />
                      </div>
                      <h4 className="title color4">
                        <a href="">Earn and Keep Growing
                        </a>
                      </h4>
                      <p className="description">
                        Once your happy customers pay up Shopkees transfers the amount at regular intervals into your given bank account . So wait for our notifications to celebrate your sales.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
        <section id="about" className="about  mt-5">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 icon-boxes text-center">
                <h3>Why choose Us?</h3>
                {/* <p> if you are an individual seller with no business of your own, you will not be permitted to become a seller on Shopkees. Shopkees seller registration allows only legally registered companies and entities to access the shopkees seller dashboard.  </p> */}
              </div>
              <div className="col-xl-10 col-lg-10 icon-boxes  ">
                <div className="row">
                  <div className="icon-box col-md-3">
                    <div className="icon">
                      <i className="bx bx-fingerprint" />
                    </div>
                    <h4 className="title">
                      <a href="">Make Your Presence Known</a>
                    </h4>
                    <p className="description">
                      Shopkees with its wide presence in the e-commerce sector has a customer base in the UAE which is ours and now your’s  to explore and grow.
                    </p>
                  </div>
                  <div className="icon-box col-md-3">
                    <div className="icon">
                      <i className="bx bx-gift" />
                    </div>
                    <h4 className="title">
                      <a href="">Manage Your Business with Ease
                      </a>
                    </h4>
                    <p className="description">
                      Our smart seller platform is designed to make the sellers manage their business very conveniently .

                    </p>
                  </div>
                  <div className="icon-box col-md-3">
                    <div className="icon">
                      <i className="bx bx-atom" />
                    </div>
                    <h4 className="title">
                      <a href="">Beyond Borders Transactions </a>
                    </h4>
                    <p className="description">
                      Our business spans the whole of GCC and hence your visibility is just not limited to the UAE but beyond borders. Receive orders from beyond and more.

                    </p>
                  </div>
                  <div className="icon-box col-md-3">
                    <div className="icon">
                      <i className="bx bx-gift" />
                    </div>
                    <h4 className="title">
                      <a href="">Seamless Payments </a>
                    </h4>
                    <p className="description">
                      Get paid faster for every sale you make directly into your account. We will notify you every time a sale is made and transfer your earnings into your bank account

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="faq" className="faq section-bg">
          <div className="container">
            <div className="section-title aos-init aos-animate" data-aos="fade-up">
              <h2>F.A.Q</h2>
              <p>Frequently Asked Questions</p>
            </div>
            <div className="faq-list">
              <ul>
                <li data-aos="fade-up" className="aos-init aos-animate">
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse collapsed"
                    data-bs-target="#faq-list-1"
                    aria-expanded="false"
                  >
                    What is Shopkees Seller?
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse"
                    data-bs-parent=".faq-list"
                    style={{}}
                  >
                    <p>
                      A platform for sellers to help businesses that are registered to find buyers across GCC using our customer base and market-span.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={100}
                  className="aos-init aos-animate"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    What categories on Shopkees are available for sellers?
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      We have opened categories like electronic products, Home Appliances, Stationeries, and many more products for our new sellers.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="aos-init aos-animate"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    What type of products cannot be listed on Shopkees.com?
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      We keep strict standards on the quality of the products as you get a share of our brand value. Hence, the list of criteria needs to be met with fr your product to be fit for listing on our seller site.  Also any illegal or hazardous products that violate the laws of the land will be outrightly rejected listing.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={300}
                  className="aos-init aos-animate"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    Why should I sell on Shopkees?
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Selling on Shopkees you open a wide gate of prospective customers across the GCC countries.Our customers span these countries and are always keen to take on the new initiatives we bring to them. You can leverage that customer base and expand your business by attracting those customers who never knew you existed.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={400}
                  className="aos-init aos-animate"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    Can I sell on Shopkee as an individual?
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Selling on Shopkees is currently available for businesses who are registered in UAE and not for individual sellers without licence. A valid trade certificate and VAT registration certificate is needed as well.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={400}
                  className="aos-init aos-animate"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    How do I get paid?
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      You  can be assured of pay as we transfer weekly settlements directly into your bank account that’s registered with the company. The amount transferred will be =sales revenue - (our fees + returns)
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <p>
                    <a
                      href="tel:+971600500550"
                      className="d-flex justify-content-center align-items-center w-100"
                    >
                      <i className="bx bxl-whatsapp" /> &nbsp; &nbsp;+971 600 500 550
                    </a>
                    <a
                      href="mailto:seller@shopkees.com"
                      className="d-flex justify-content-center align-items-center w-100"
                    >
                      <i className="bx bxs-envelope" /> &nbsp;
                      &nbsp;seller@shopkees.com
                    </a>
                  </p>
                  <div className="social-links mt-3">
                    <a href="https://twitter.com/Shopkees_com/" className="twitter">
                      <i className="bx bxl-twitter" />
                    </a>
                    <a href="https://www.facebook.com/shopkees/" className="facebook">
                      <i className="bx bxl-facebook" />
                    </a>
                    <a
                      href="https://www.instagram.com/shopkees/"
                      className="instagram"
                    >
                      <i className="bx bxl-instagram" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/shopkees/"
                      className="linkedin"
                    >
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/shopkees/"
                      className="linkedin"
                    >
                      <i className="bx bxl-youtube" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 footer-links">
                <h4>About Us</h4>
                <p style={{ fontSize: 13, color: "#ccc" }}>
                  Shopkees started its journey in retail in 2003 and with its decade
                  long customer base began the online store in 2014 as a leading
                  electronics and home appliance e-store in UAE and the GCC. Shopkees
                  is an electronics hub for smartphones, laptops, all types of
                  computer accessories and much more. Lightning fast delivery, buy now
                  pay later, etc. are our key features. Secure payment methods and
                  secure gateways add to our security
                </p>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="https://www.shopkees.com/uae-en/page/terms-and-condition.html" target="__blank">Terms &amp; Conditions </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" /> <a href="https://www.shopkees.com/uae-en/contact.html" target="__blank">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Quick Link</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="https://www.youtube.com/watch?v=M7vzDuT8qPs" target="__blank" >How it works </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" /> <a href="#faq">FAQ </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" /> <a href="#about">Benefits </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>


      {/* End Footer */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
    </>



  );
}

export default Login;
