import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-modal";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import axios from "../../Utils/axios";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import { makeStyles } from "@mui/styles";
import { DateRangePicker } from "react-dates";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  PresetDateRangePicker_panel: {
    padding: "0 22px 11px 22px",
  },

  PresetDateRangePicker_button: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
  },

  PresetDateRangePicker_button__selected: {
    position: "relative",
    height: "100%",
    textAlign: "center",
    background: "none",
    border: `2px solid #138496 `,
    color: "#138496",
    padding: "4px 12px",
    marginRight: 8,
    font: "inherit",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "visible",
    boxSizing: "border-box",
    cursor: "pointer",

    ":active": {
      outline: 0,
    },
    color: "white",
    background: "#138496",
  },
}));

function DeliveredOrders(props) {
  const history = useHistory();
  const today = moment();
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [orderId, setOrderId] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const alert = useAlert();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const openModal = (order_id) => {
    setOrderId(order_id);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const yesterday = moment().add(-1, "day");
  const presets = [
    {
      text: "Today",
      start: today,
      end: today,
    },
    {
      text: "Yesterday",
      start: yesterday,
      end: yesterday,
    },
    {
      text: "This Week",
      start: moment().add(-1, "week"),
      end: today,
    },
    {
      text: "This Month",
      start: moment().add(-1, "month"),
      end: today,
    },
    {
      text: "This Year",
      start: moment().add(-1, "year"),
      end: today,
    },
  ];

  const onDatesChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
  };

  function isSameDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return (
      a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
    );
  }

  function renderDatePresets() {
    return (
      <div className={classes.PresetDateRangePicker_panel}>
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              className={
                isSelected
                  ? classes.PresetDateRangePicker_button__selected
                  : classes.PresetDateRangePicker_button
              }
              type="button"
              onClick={() => onDatesChange(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  useEffect(() => {
    orders_list();
  }, [page, per_page, search, endDate, startDate]);
  const viewDetails = (id) => {
    history.push(`/order-details/${id}`);
  };
  const updateOrderStatus = (order_id, status) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_id", order_id);
    formdata.append("status", status);
    axios(`Orders/updateSellerOrderStatus`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          orders_list();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const orders_list = () => {
    setLoading(true);
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Orders/seller_order_list?order_status=delivered&page=${page}&per_page=${per_page}&start_date=${start}&end_date=${end}&search=${search}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        console.log(res);
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setOrders([]);
            props.setDeliveredcount(res.data.total_count);
            setTotal(res.data.total);
            setLoading(false);
          } else {
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setDeliveredcount(res.data.total_count);

            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Shipped Orders</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div className="row">
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
              <div className="col-md-6">
                <DateRangePicker
                  presets={presets}
                  renderCalendarInfo={renderDatePresets}
                  showClearDates={true}
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Customer</th>
                        <th>Total</th>
                        <th>Commission</th>
                        <th>Net Amount</th>
                        <th>Order Date</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {orders.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.order_no}</td>
                            <td>{value.customer}</td>
                            <td>{value.total + " " + value.currency}</td>
                            <td>{value.commission + " " + value.currency}</td>
                            <td>
                              {value.seller_amount + " " + value.currency}
                            </td>
                            <td>{value.date_ordered}</td>
                            <td className="space-evenly">
                              <button
                                className="btn btn-sm  btn-primary"
                                title="View"
                                onClick={() => viewDetails(value.id)}
                              >
                                <i className="fa fa-eye  fa-sm"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>
                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveredOrders;
