import React, { useState, useContext, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PendingTransactions from "./PaymentTabs/PendingTransactions";
import CompletedTransactions from "./PaymentTabs/CompletedTransaction";
import Payout from "./PaymentTabs/Payout";
function Products() {
  const [pending, setPending] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [payout, setPayout] = useState(0);

  return (
    <Tabs>
      <TabList>
        <Tab>
          Pending
          <span class="badge badge-danger badge-counter">{pending}</span>
        </Tab>
        <Tab>
          Completed
          <span class="badge badge-success badge-counter">{completed}</span>
        </Tab>
        <Tab>
          Payout
          <span class="badge badge-info badge-counter">{payout}</span>
        </Tab>
      </TabList>

      <TabPanel>
        <PendingTransactions setPending={setPending}></PendingTransactions>
      </TabPanel>
      <TabPanel>
        <CompletedTransactions setCompleted={setCompleted}></CompletedTransactions>
      </TabPanel>
      <TabPanel>
        <Payout setPayout={setPayout}></Payout>
      </TabPanel>
    </Tabs>
  );
}

export default Products;
