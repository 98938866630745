import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AllProducts from './ProductTabs/AllProducts'
import ActiveProducts from './ProductTabs/ActiveProducts'
import InactiveProducts from './ProductTabs/InactiveProducts'
import OutofstockProducts from './ProductTabs/OutofstockProducts'
import DraftProducts from "./ProductTabs/DraftProducts";
import LockedProducts from "./ProductTabs/LockedProducts"
import FeedProducts from "./ProductTabs/FeedProducts";
import IsAllowed from "../Utils/IsAllowed";

import './Products.css';

function Products() {
  const [allcount, setAllcount] = useState(0);
  const [activecount, setActivecount] = useState(0);
  const [inactivecount, setInactivecount] = useState(0);
  const [outofstockcount, setOutofstockcount] = useState(0);
  const [draftcount, setDraftcount] = useState(0);
  const [lockedcount, setLockedcount] = useState(0);
  const [feedcount, setFeedcount] = useState(0);


  return (
    <Tabs>
      <TabList>
        <Tab>Active <span class="badge badge-success badge-counter">{activecount}</span></Tab>
        <Tab>Inactive <span class="badge badge-danger badge-counter">{inactivecount}</span></Tab>
        <Tab>Out of stock <span class="badge badge-warning badge-counter">{outofstockcount}</span></Tab>
        <Tab>Draft <span class="badge badge-primary badge-counter">{draftcount}</span></Tab>
        <Tab>Locked <span class="badge badge-secondary badge-counter">{lockedcount}</span></Tab>
        <Tab>All <span class="badge badge-info badge-counter">{allcount}</span></Tab>
        {IsAllowed(["product_view"], ["feed_action"]) && (
          <Tab>Feed <span class="badge badge-info badge-counter">{feedcount}</span></Tab>
        )}
      </TabList>


      <TabPanel>
        <ActiveProducts setActivecount={setActivecount}></ActiveProducts>
      </TabPanel>
      <TabPanel>
        <InactiveProducts setInactivecount={setInactivecount}></InactiveProducts>
      </TabPanel>
      <TabPanel>
        <OutofstockProducts setOutofstockcount={setOutofstockcount}></OutofstockProducts>
      </TabPanel>
      <TabPanel>
        <DraftProducts setDraftcount={setDraftcount}></DraftProducts>
      </TabPanel>
      <TabPanel>
        <LockedProducts setLockedcount={setLockedcount}></LockedProducts>
      </TabPanel>
      <TabPanel>
        <AllProducts setAllcount={setAllcount}></AllProducts>
      </TabPanel>
      {IsAllowed(["product_view"], ["feed_action"]) && (
        <TabPanel>
          <FeedProducts setFeedcount={setFeedcount}></FeedProducts>
        </TabPanel>
      )}
    </Tabs>
  );
}

export default Products;
