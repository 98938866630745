import { useContext, useEffect } from "react";
import { AuthContext } from "../../Context/AuthContext";
//import axios from "../Utils/axios";

function IsAllowed(rights,feature_items=['basic']) {
  const { role, permissions,features } = useContext(AuthContext);
   if(feature_items.some((feature_item) => features.includes(feature_item))){
  if (role == 1) {
    return true;
  } else {
    return rights.some((right) => permissions.includes(right));
  }
}else{
  return false;
}
}
export default IsAllowed;

//export const hasRole = (user, roles) =>
// roles.some(role => user.roles.includes(role));
