import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../Utils/axios";
import { useHistory, Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Switch from "react-switch";
import fileDownload from "js-file-download";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
function CompletedTransactions(props) {
  const history = useHistory();
  const [transactions, setTransactions] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const alert = useAlert();

  useEffect(() => {
    getTransactions();
  }, [page, per_page, search]);
  const updateUser = (id) => {
    history.push({
      pathname: "/transaction-details",
      state: { id: id },
    });
  };

  const getTransactions = () => {
    setLoading(true);
    axios(
      `Transactions/get_seller_transactions?page=${page}&per_page=${per_page}&search=${search}&txn_status=completed&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setTransactions([]);
            props.setCompleted(res.data.total_count);
            setLoading(false);
            setTotal(res.data.total);
          } else {
            setTransactions(res.data.data);
            props.setCompleted(res.data.total_count);
            setTotal(res.data.total);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };const export_transaction_list = async () => {
    setLoading(true);
    let start = "";
    // startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    //endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    axios(
      `Transactions/export_seller_transaction_list?start_date=${start}&end_date=${end}&txn_status=completed&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, "transaction.csv");
      })
      .catch((err) => console.log(err));
  };



  return (
    <div>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Transactions</h1>

        <div class="card shadow mb-4">
          <div class="card-body">
          <div className="col-md-1 offset-11">
              <input
                type="button"
                onClick={export_transaction_list}
                className="btn btn-success"
                value="export"
                style={{
                  zIndex: 1,
                  // borderTopLeftRadius: 0,
                  // borderBottomLeftRadius: 0,
                }}
              />
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>Id</th>
                       
                        <th>OrderId</th>
                        <th>Product Name </th>
                        <th>Time</th>
                        <th>Amount </th>
                      </tr>
                    </thead>

                    <tbody>
                      {transactions.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Link
                                style={{ cursor: "pointer", color: "#0645AD" }}
                                to={{
                                  pathname: `/transaction-details/${value.id}`,
                                }}
                              >
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#0645AD",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {value.id}
                                </span>
                              </Link>
                            </td>

                            <td>{value.order_no}</td>
                            <td>{value.product_name}</td>
                            <td>{value.time}</td>
                            <td>{value.currency + " " + value.total}</td>
                            
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletedTransactions;
