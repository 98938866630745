import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import axios from "../Utils/axios";

function UserRoles() {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const alert=useAlert();

  useEffect(() => {
    getRolePermission();
  }, [selectedRole]);
  useEffect(() => {
    getUserRoles();
    getUserPermissions();
  }, []);

  const permissionChangeHandle = (val, e) => {
    let permissionArray = [];
    if (!e.target.checked) {
      for (let i = 0; selectedPermission.length > i; i++) {
        if (selectedPermission[i] == val) {
        } else {
          permissionArray.push(selectedPermission[i]);
        }
      }
      setSelectedPermission(permissionArray);
    } else {
      permissionArray = selectedPermission.slice();
      if (selectedPermission.includes(val)) {
      } else {
        permissionArray.push(val);
      }
      setSelectedPermission(permissionArray);
    }
  };

  const updateRolePermission = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("permissions", JSON.stringify(selectedPermission));
    formdata.append("role", selectedRole);
    axios(`Users/update_role_permission`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
          
        } else {
          setLoading(false);
          alert.show(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const getRolePermission = () => {
    setLoading(true);
    axios(`Users/get_role_permissions?role_id=${selectedRole}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
          setSelectedPermission(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getUserRoles = () => {
    setLoading(true);
    axios(`Users/get_user_roles`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
          setSelectedRole(res.data.data[0].id);
          setRoles(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getUserPermissions = () => {
    setLoading(true);
    axios(`Users/get_user_permissions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
          setPermissions(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.9)",
        }),
        spinner: (base) => ({
          ...base,
          width: "75px",
          "& svg circle": {
            stroke: "rgba(0, 0, 0, 0.8)",
          },
        }),
      }}
      spinner
      text="Loading ..."
    >
      <div>
        <div class="card">
          <div className="row">
            <div className="col-md-6">
              {roles.map((value, index) => {
                return (
                  <div key={index}>
                    <input
                      type="checkbox"
                      onChange={(e) => setSelectedRole(e && value.id)}
                      checked={selectedRole == value.id ? 1 : 0}
                      //onClick={(e) => setSelectedRole(e && value.id)}
                      value={value.id}
                    ></input>
                    <label>{value.role_name}</label>
                  </div>
                );
              })}
            </div>
            <div className="col-md-6">
              {permissions.map((value, index) => {
                return (
                  <div key={index}>
                    <input
                      type="checkbox"
                      onChange={(e) => permissionChangeHandle(value.slug, e)}
                      checked={selectedPermission.includes(value.slug)}
                      value={value.slug}
                    ></input>
                    <label>{value.name}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <input
            type="button"
            className="btn btn-success"
            value="Update"
            onClick={updateRolePermission}
          ></input>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default UserRoles;
