import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import IsAllowed from "../Utils/IsAllowed";

function SideBar() {
  function toggle() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $(".sidebar .collapse").collapse("hide");
    }
  }

  return (
    <ul
      class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion "
      id="accordionSidebar"
    >
      <Link
        class="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div class="sidebar-brand-icon ">
          <img src="/logo.svg" height="150px" width="150px"></img>
        </div>
      </Link>

      <hr class="sidebar-divider my-0" />

      <li class="nav-item active">
        <Link class="nav-link" to="/">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      <hr class="sidebar-divider" />

      {IsAllowed(["product_view"]) && (
        <li className="nav-item">
          <Link
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i class="fas fa-fw fa-cubes"></i>
            <span>Products</span>
          </Link>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              <Link class="collapse-item" to="/products">
                Products
              </Link>
              <Link class="collapse-item" to="/add-products">
                Add Products
              </Link>
              {IsAllowed(["product_view"], ["product_edit_cost"]) && (
                <Link class="collapse-item" to="/product-edit-cost">
                  Product Edit Cost
                </Link>
              )}
            </div>
          </div>
        </li>
      )}

      {IsAllowed(["order_view"]) && (
        <li class="nav-item">
          <Link
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i class="fas fa-fw fa-shopping-cart"></i>
            <span>Orders</span>
          </Link>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              <Link class="collapse-item" to="/orders">
                Orders
              </Link>
            </div>
          </div>
        </li>
      )}

      {IsAllowed(["user_view"], ["user_management"]) && (
        <li class="nav-item">
          <Link
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseFour"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i class="fas fa-fw fa-users"></i>
            <span>Users & Roles</span>
          </Link>
          <div
            id="collapseFour"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              <Link class="collapse-item" to="/users">
                Users
              </Link>
              <Link class="collapse-item" to="/user-add">
                Add User
              </Link>
              <Link class="collapse-item" to="/user-roles">
                User Roles
              </Link>
            </div>
          </div>
        </li>
      )}
      {IsAllowed(["user_view"],["price_setting"]) && (
        <li class="nav-item">
          <Link
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseFive"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i class="fas fa-fw fa-cogs"></i>
            <span>Settings</span>
          </Link>
          <div
            id="collapseFive"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              <Link class="collapse-item" to="/cost-percentage">
                Cost Percentage
              </Link>
              {/* <Link class="collapse-item" to="/offer-percentage">
                Offer Percentage
              </Link> */}
            </div>
          </div>
        </li>
      )}

      {IsAllowed(["order_view"]) && (
        <li class="nav-item">
          <Link
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseSix"
            aria-expanded="true"
            aria-controls="collapseSix"
          >
            <i class="fas fa-exchange-alt"></i>
            <span>Payments</span>
          </Link>
          <div
            id="collapseSix"
            class="collapse"
            aria-labelledby="headingSix"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              <Link class="collapse-item" to="/transactions">
                Transactions
              </Link>
            </div>
          </div>
        </li>
      )}

      <div class="text-center d-none d-md-inline">
        <button
          class="rounded-circle border-0"
          onClick={toggle}
          id="sidebarToggle"
        ></button>
      </div>
    </ul>
  );
}

export default SideBar;
