import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../Utils/axios";
import { IMAGE_URL } from "../../constants/constants";
import Modal from "react-modal";
import { useAlert } from "react-alert";
import { makeStyles } from "@mui/styles";

function OrderDetails(props) {
  const [orderId, setOrderId] = useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState("");
  useEffect(() => {
    getOrderDeatail();
  }, [orderId]);

  const alert = useAlert();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const openModal = (order_id) => {
    setOrderId(order_id);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const updateOrderStatus = (order_id, status) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_id", order_id);
    formdata.append("status", status);
    axios(`Orders/updateSellerOrderStatus`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          setLoading(false);
          getOrderDeatail();
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const cancellOrder = () => {
    if (comment == "") {
      alert("enter a reason");
    } else {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("order_id", orderId);
      formdata.append("status", "cancelled");
      formdata.append("comment", comment);
      axios(`Orders/updateSellerOrderStatus`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            closeModal();
            getOrderDeatail();
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getOrderDeatail = () => {
    setLoading(true);
    axios(`Orders/get_seller_order_detail?order_id=${orderId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status === false) {
        } else {
          setOrder(res.data.data.order);
          setOrderItems(res.data.data.order_items);
          setCustomer(res.data.data.customer);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div class="container-fluid">
      <Modal
        isOpen={isOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>Cancel reason</h2>
        <textarea
          type="text"
          className="form-control"
          name={comment}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <input type="button" onClick={() => cancellOrder()} value="Confirm" />
        <input type="button" onClick={closeModal} value="Cancel" />
      </Modal>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <h1 class="h3 mb-2 text-gray-800">Order Details</h1>

       
        <div class="card shadow mb-4">
          <div className="row order-detail mt-5">
            <h5> Order Id : {order.order_no} </h5>
            <h5>Purchased On : {order.created_at}</h5>
            <h5>
              Customer : {customer.name}
            </h5>
            <div className="col-md-6"></div>
            <div className="col-md-6 text-right pr-5">
            {order.seller_order_status == 18  || order.status_id == 7  &&(
                <input
                  type="button"
                  className="btn btn-danger mr-2"
                  value="Cancelled"
                  disabled
                ></input>
              )}
              {order.seller_order_status == 15  && order.status_id != 6 && order.status_id != 7  &&(
                <input
                  type="button"
                  className="btn btn-success mr-2"
                  value="Confirm"
                  onClick={() => updateOrderStatus(order.id, "confirmed")}
                ></input>
              )}
              {order.seller_order_status == 16 && order.status_id != 6  && order.status_id != 7 && (
                <input
                  type="button"
                  className="btn btn-info mr-2"
                  value="Ready For Ship"
                  onClick={() => updateOrderStatus(order.id, "packed")}
                ></input>
              )}
              {order.seller_order_status == 17 &&  order.status_id != 6  && order.status_id != 7 && (
                <input
                  type="button"
                  className="btn btn-warning mr-2"
                  value="Shipped"
                  onClick={() => updateOrderStatus(order.id, "shipped")}
                ></input>
              )}
              {order.seller_order_status == 15 &&  order.status_id != 6  && order.status_id != 7 && (
                <input
                  type="button"
                  className="btn btn-danger mr-2"
                  value="Cancel"
                  onClick={
                    () => openModal(order.id)
                    //updateOrderStatus(value.id, "cancelled")
                  }
                ></input>
              )}
            </div>
          </div>
          <div class="card-body">
            <div className="table-responsive">
              <table className="table table-bordered  ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Image</th>
                    <th>Unit Price</th>
                    <th>Qty</th>
                    <th>Total</th>
                    <th>Commission Amount</th>
                    <th>Net</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {orderItems.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>{value.name}</td>
                        <td>
                          <img
                            alt=""
                            src={IMAGE_URL + value.item_img_url}
                          ></img>
                        </td>
                        <td>
                          {value.special_price == 0
                            ? value.price
                            : value.special_price}
                        </td>
                        <td>{value.qty}</td>
                        <td>{value.subtotal}</td>
                        <td>{value.commission_amount}</td>
                        <td>{value.net}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default OrderDetails;
