import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../Utils/axios";
import { useHistory, Link } from "react-router-dom";
import { useAlert } from "react-alert";

function TransactionDetails(props) {
  const history = useHistory();
  const [transactionId, setTransactionId] = useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [loading, setLoading] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({});
  const alert = useAlert();

  useEffect(() => {
    getTransactionDetail();
  }, []);

  const getTransactionDetail = () => {
    setLoading(true);
    axios(`Transactions/get_seller_transaction_details?id=${transactionId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setTransactionDetails(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner
        text="..."
      >
        {Object.keys(transactionDetails).length > 0 && (
          <>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h3>Time : </h3>
                </div>
                <div className="col-md-6">
                  {transactionDetails && transactionDetails.created_at}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h3>Order Id : </h3>
                </div>
                <div className="col-md-6">
                  {transactionDetails && transactionDetails.order_id}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h3>Product Name :</h3>
                </div>
                <div className="col-md-6">
                  {transactionDetails && transactionDetails.name}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h3>Qty :</h3>
                </div>
                <div className="col-md-6">
                  {transactionDetails && transactionDetails.qty}
                </div>
              </div>
              <br></br>
            </div>
            <hr></hr>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h6>Order Amount : </h6>
                </div>
                <div className="col-md-6">
                  {transactionDetails &&
                    transactionDetails.currency +
                      " " +
                      transactionDetails.order_amount}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h6>Commission : </h6>
                </div>
                <div className="col-md-6">
                  {transactionDetails &&
                    transactionDetails.currency +
                      " " +
                      transactionDetails.commission_amount}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h6>Total : </h6>
                </div>
                <div className="col-md-6">
                  {transactionDetails &&
                    transactionDetails.currency +
                      " " +
                      transactionDetails.total}
                </div>
              </div>
              <br></br>
            </div>
          </>
        )}
      </LoadingOverlay>
    </div>
  );
}

export default TransactionDetails;
