import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import fileDownload from "js-file-download";
import axios from "../Utils/axios";
export default function PayoutDetails(props) {
  const [payoutId, setPayoutId] = useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [loading, setLoading] = useState(false);
  const [payoutDetails, setPayoutDetails] = useState({});

  useEffect(() => {
    getPayoutDetail();
  }, []);

  const getPayoutDetail = () => {
    setLoading(true);
    axios(`Transactions/get_seller_payout_details?id=${payoutId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setPayoutDetails(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const export_payout_list = async () => {
    setLoading(true);

    axios(
      `Transactions/export_seller_payout_list?txn_id=${payoutId}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, "payout.csv");
      })
      .catch((err) => console.log(err));
  };
  return (
    <div class="card shadow mb-4 m-2">
      <div class="card-body">
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 0.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "rgba(0, 0, 0, 0.8)",
              },
            }),
          }}
          spinner
          text="..."
        >




          {Object.keys(payoutDetails).length > 0 && (
            <>
              <div className="row order-row">


                <div className="col-md-11 p-0">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                      <h5 className="mb-0 payoutdetail">Sales : </h5>

                      <b className="payoutprice"> {payoutDetails && (payoutDetails.currency + " " + payoutDetails.sales)}</b>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                      <h5 className="mb-0 payoutdetail">Commission : </h5>

                      <b className="payoutprice"> {payoutDetails && (payoutDetails.currency + " " + payoutDetails.commission)}</b>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                      <h5 className="mb-0 payoutdetail">Total :</h5>

                      <b className="payoutprice"> {payoutDetails && (payoutDetails.currency + " " + payoutDetails.total)}</b>
                    </div>
                  </div>

                </div>
                <div className="col-md-1">
                  <input
                    type="button"
                    className="btn btn-success"
                    onClick={export_payout_list}
                    value="Export"
                  />
                </div>
              </div>
              <h4 className="mt-3"><b>Transactions ({payoutDetails && (payoutDetails.count)}) </b></h4>
              <table className="table table-bordered  ">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>OrderId</th>
                    <th>Order Amount</th>
                    <th>Commission </th>
                    <th>Amount</th>

                  </tr>
                </thead>

                <tbody>
                  {payoutDetails.items.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>{value.name}</td>
                        <td>{value.order_no}</td>
                        <td>{"AED " + value.order_amount}</td>
                        <td>{"AED " + value.commission_amount}</td>
                        <td>{"AED " + value.total}</td>



                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </LoadingOverlay>
      </div>
    </div>
  )
}
