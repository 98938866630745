import axios from "../Utils/axios";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import './Products.css';
function PriceHistory(props) {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  useEffect(() => {
    getProductPrice();
  }, [props.product_id]);

  const getProductPrice = () => {
    setLoading(true);

    axios(
      `Products/get_seller_product_price_history?product_id=${props.product_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    ).then((res) => {
      if (res.data.status) {
        setProduct(res.data.data);
        setLoading(false);
      } else {
        alert.show(res.data.message);
      }
    });
  };

  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.9)",
        }),
        spinner: (base) => ({
          ...base,
          width: "75px",
          "& svg circle": {
            stroke: "rgba(0, 0, 0, 0.8)",
          },
        }),
      }}
      spinner
      text="Loading ..."
    >
      <div>
        <div class="card shadow mb-4">
          <div class="card-body">
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive"></div>
              <table className="table table-bordered  ">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Price</th>
                    <th>Special Price</th>
                    <th>Cost</th>
                    <th>Stock</th>
                    <th>Created By</th>
                    <th>Updated Field</th>
                    <th>Created Date</th>
                  </tr>
                </thead>

                <tbody>
                  {product.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value.price}</td>
                        <td>{value.sale_price}</td>
                        <td>{value.cost}</td>
                        <td>{value.stock_qty}</td>
                        <td>{value.updated_by}</td>
                        <td>{value.last_updated_feild}</td>
                        <td>{value.created_date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default PriceHistory;